<template>
  <c-flex 
    :flex-direction="['column', 'row']"
    justify-content="center"
    align-items="center"
    :py="['24px', '0px']"
    :px="['0px', '24px']"
    :gap="['0px', '64px']"
  >
    <c-flex
      flex-direction="column"
      align-items="center"
      gap="1rem"
      :width="['100%', '500px']"
    >
      <c-image
        class="header__icon-dietela"
        src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
        alt="icon logo dietela"
      />
      <c-image
        class="header__icon-login"
        :src="iconLogin"
        alt="icon login dietela"
      />
    </c-flex>
    <c-flex
      flex-direction="column"
      align-items="center" 
      justify-content="center"
      :width="['100%', '520px']"
      :height="['auto', '100vh']"
      :py="['0px', '0px']"
      :px="['16px', '24px']"
      class="md:overflow-auto md:scrollbar-hide"
    >
      <h1 class="title">
        Selamat Datang!
      </h1>
      <c-flex
        flex-direction="column"
        width="100%"
      >
        <div class="tab-header">
          <a
            class="tab-header__item"
            :class="$route.query.tab === 'login' ? 'tab-header__item--active' : ''"
            @click="setTabActive('login')"
          >Masuk</a>
          <a
            class="tab-header__item"
            :class="$route.query.tab === 'signup' ? 'tab-header__item--active' : ''"
            @click="setTabActive('signup')"
          >Daftar</a>
        </div>
        <FormulateForm
          v-show="$route.query.tab === 'login'"
          v-slot="{ isValid, isLoading }"
          v-model="formValuesLogin"
          class="tab-form"
          style="overflow: hidden;"
          :debounce="50"
          @submit="handleLogin"
        >
          <FormulateInput
            data-testid="input-email"
            name="email"
            label="Email"
            :icon-prefix="iconEmail"
            :icon-prefix-style="{
              width: '20px'
            }"
            placeholder="Masukkan email"
            validation="required"
            type="text"
            @input="handleSessionEmail"
          />
          <FormulateInput
            data-testid="input-password"
            name="password"
            label="Kata Sandi"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required"
            type="password"
          />
          <div class="tab-form-action-1">
            <c-flex 
              align-items="center"
              justify-content="center"
              gap="8px"
            >
              <c-checkbox
                variant-color="primary"
                :is-checked="isChecked"
                @change="handleRememberMe"
              >
                <c-text :font-size="['12px', '14px']">
                  Ingat saya
                </c-text>
              </c-checkbox>
            </c-flex>
            <router-link
              to="/forgot-password"
              data-testid="link-forgot-password"
            >
              <div class="tab-form-action__link tab-form-action__link--hover">
                <c-link>
                  Lupa Kata Sandi?
                </c-link>
              </div>
            </router-link>
          </div>
          <div class="tab-form-action">
            <c-box width="100%">
              <d-button
                type="submit"
                :disabled="!isValid || isLoading"
              >
                Masuk
              </d-button>
              <c-box
                text-align="center"
                margin-top="8px"
                margin-bottom="8px"
                pos="relative"
              >
                <c-box
                  display="block"
                  width="100%"
                  height="1px"
                  bg-color="#C4C4C4"
                  pos="absolute"
                  top="50%"
                  left="0"
                  margin-top="-0.5px"
                />
                <c-box
                  as="span"
                  pos="relative"
                  z-index="1"
                  color="#C4C4C4"
                  display="inline-block"
                  bg-color="white"
                  py="6px"
                  px="12px"
                  font-size="12px"
                >
                  atau
                </c-box>
              </c-box>
              <d-button
                variant="outlined"
                :disabled="disabled"
                :icon-start="iconGoogle"
                @click="handleGoogleLogin"
              >
                Masuk dengan Google
              </d-button>
            </c-box>
            <p class="tab-form-action__link">
              Belum punya akun? {{ " " }}
              <a
                class="tab-form-action__link--hover"
                @click="setTabActive('signup')"
              >Daftar</a>
            </p>
          </div>
        </FormulateForm>

        <FormulateForm
          v-show="$route.query.tab === 'signup'"
          v-slot="{ isValid, isLoading }"
          v-model="formValues"
          class="tab-form"
          :debounce="50"
          @submit="handleSignUp"
        >
          <c-grid
            w="100%"
            :template-columns="['repeat(2, 1fr)']"
            :gap="['8px', '16px']"
            mx="auto"
          >
            <FormulateInput
              name="firstname"
              label="Nama Depan"
              :icon-prefix="iconProfile"
              :icon-prefix-style="{
                marginTop: '-2px',
              }"
              placeholder="Nama depan"
              validation="required"
              type="text"
            />
            <FormulateInput
              name="lastname"
              label="Nama Belakang"
              :icon-prefix="iconProfile"
              :icon-prefix-style="{
                marginTop: '-2px',
              }"
              placeholder="Nama belakang"
              validation="required"
              type="text"
            />
          </c-grid>

          <!-- Comment Input Birth Date and Country -->
          <!-- <FormulateInput
            name="birthdate"
            label="Tanggal Lahir"
            :icon-prefix="iconCalendar"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan tanggal lahir"
            validation="required"
            type="date"
          />
          <FormulateInput
            name="country"
            label="Negara"
            :icon-prefix="iconCountry"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan negara"
            validation="required"
            type="select"
            :options="countries"
          /> -->

          <FormulateInput
            name="phone"
            label="Nomor Telepon"
            :icon-prefix="iconPhone"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan nomor telepon"
            validation="required|number"
            type="tel"
          />
          <FormulateInput
            name="email"
            label="Email"
            :icon-prefix="iconEmail"
            :icon-prefix-style="{
              width: '20px'
            }"
            placeholder="Masukkan email"
            validation="required|email"
            type="text"
          />
          <FormulateInput
            name="password"
            label="Kata Sandi"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required|min:8,length|hasAlphaNum|hasSpecialCharacter|hasUpperCase|hasLowerCase"
            type="password"
          />
          <FormulateInput
            name="password_confirm"
            label="Ulangi Kata Sandi"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required|confirm"
            validation-name="Password"
            type="password"
          />
          <div class="tab-form-action">
            <d-button
              type="submit"
              :disabled="!isValid || isLoading"
            >
              Daftar
            </d-button>
            <d-button
              variant="outlined"
              :disabled="disabled"
              :icon-start="iconGoogle"
              @click="handleGoogleSignup"
            >
              Daftar dengan Google
            </d-button>
            <p class="tab-form-action__link">
              Belum punya akun? {{ " " }}
              <a
                class="tab-form-action__link--hover"
                @click="setTabActive('login')"
              >Masuk</a>
            </p>
          </div>
        </FormulateForm>
      </c-flex>
    </c-flex>
    <ModalEmailVerification
      v-show="isModalOpen"
      :is-open="isModalOpen"
      :email="formValues.email"
    />
    <ModalCannotLogin
      v-show="isLoginError"
      :is-open="isLoginError"
      :data="errorMessage"
      @handle-close="handleCloseModalErrorCannotLogin"
    />
  </c-flex>
</template>

<script >
import iconLogin from '@/assets/ic-login.svg?inline';
import iconGoogle from '@/assets/ic-google.svg';
import iconProfile from '@/assets/ic-profile.svg'; // import iconCalendar from '@/assets/ic-calendar.svg'
// import iconCountry from '@/assets/ic-block.svg'

import iconPhone from '@/assets/ic-phone.svg';
import iconEmail from '@/assets/ic-mail.svg';
import iconPassword from '@/assets/ic-padlock.svg';
import DButton from '@/components/elements/d-button.vue';
import ModalEmailVerification from '@/components/widgets/modal-email-verification.vue';
import ModalCannotLogin from '@/components/widgets/modal-error-cannot-login.vue';
import { onMounted, ref } from '@vue/composition-api';
import { store, useActions, useMutations } from '@/stores';
import { useRouter, useRoute } from 'vue2-helpers/vue-router'; // import { useQuery } from '@tanstack/vue-query'

import { useToast } from '@chakra-ui/vue'; // import { MILISECONDS_FOR } from '@/constants/miliseconds'

import posthog from 'posthog-js';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const toast = useToast();
  const router = useRouter();
  const route = useRoute();
  const {
    signin,
    signup
  } = useActions('auth', ['signin', 'signup']);
  const {
    setRedirect
  } = useMutations('auth', ['setRedirect']);
  const redirect = ref(route.query.redirect);
  const isLoginError = ref(false);
  const errorMessage = ref();
  const formValuesLogin = ref({
    email: localStorage.getItem('isSaveSession') === 'true' ? localStorage.getItem('email') : '',
    password: ''
  });
  const formValues = ref({
    firstname: '',
    lastname: '',
    // birthdate: '',
    // country: '',
    phone: '',
    email: '',
    password: '',
    password_confirm: ''
  });
  const disabled = ref(false);
  const isModalOpen = ref(false);
  const isChecked = ref(localStorage.getItem('isSaveSession') === 'true');

  const handleSessionEmail = value => {
    localStorage.setItem('email', value);
  }; // Comment fetching list contries
  // const { data: countries } = useQuery({
  //   queryKey: ['list countries'],
  //   queryFn: () => store.getters.axios.get('/v1/general/countries').then((it) => {
  //     return it.data.data.map((data) => {
  //       return {
  //         value: data.name,
  //         label: data.name,
  //       }})
  //   }),
  //   staleTime: MILISECONDS_FOR.ONE_DAY,
  // })


  onMounted(() => {
    const initialTab = ['login', 'signup'].includes(route.query.tab) ? route.query.tab : 'login';
    router.replace({
      query: {
        tab: initialTab,
        redirect: redirect.value
      }
    }).catch(() => {});
  });

  const setTabActive = tab => {
    posthog.capture('visitor want to signup');
    router.replace({
      query: {
        tab,
        redirect: redirect.value
      }
    }).catch(() => {});
  };

  const handleCloseModalErrorCannotLogin = () => {
    isLoginError.value = false;
  };

  const handleRememberMe = (val, e) => {
    if (e.target.checked) {
      localStorage.setItem('email', formValuesLogin.value.email);
    } else {
      localStorage.removeItem('email');
    }

    localStorage.setItem('isSaveSession', e.target.checked);
    isChecked.value = e.target.checked;
  };

  const handleGoogleLogin = () => {
    if (redirect.value) {
      setRedirect(redirect.value);
    } else {
      setRedirect({
        name: route.name
      });
    }

    store.getters.axios.post('/v1/auth/generate-google-url').then(res => {
      const url = res.data.data;
      window.open(url, '_self');
    });
  };

  const handleGoogleSignup = () => {
    posthog.capture('visitor want to signup');
    setTimeout(() => {
      posthog.capture('visitor have signup');
    }, 2000);
    handleGoogleLogin();
  };

  const handleLogin = async _formValues => {
    await signin({
      email: _formValues.email,
      password: _formValues.password
    }).then(() => {
      store.dispatch('footer/getFooter');
      toast({
        status: 'success',
        title: 'Success',
        description: 'login sukses',
        duration: 3000
      });

      if (redirect.value) {
        router.push(redirect.value);
      } else {
        router.push('/');
      }
    }).catch(e => {
      isLoginError.value = true;

      if (e?.message === 'Akun kamu terdaftar melalui login dengan Google') {
        errorMessage.value = 'Mohon maaf, akun kamu terdaftar melalui Google. Silahkan lakukan login ulang menggunakan metode login dengan Google';
      } else errorMessage.value = 'Mohon maaf, sepertinya terdapat kesalahan data. Mohon untuk mengecek ulang data anda.';

      toast({
        status: 'error',
        title: 'Error',
        description: e?.message,
        duration: 3000
      });
    });
  };

  const handleSignUp = async _formValues => {
    await signup(_formValues).then(() => {
      isModalOpen.value = true;
      posthog.capture('visitor want to signup');
      setTimeout(() => {
        posthog.capture('visitor have signup');
      }, 2000);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error',
        description: err?.message,
        duration: 3000
      });
    });
  };

  return {
    iconLogin,
    iconGoogle,
    iconProfile,
    iconPhone,
    iconEmail,
    iconPassword,
    isLoginError,
    errorMessage,
    formValuesLogin,
    formValues,
    disabled,
    isModalOpen,
    isChecked,
    handleSessionEmail,
    setTabActive,
    handleCloseModalErrorCannotLogin,
    handleRememberMe,
    handleGoogleLogin,
    handleGoogleSignup,
    handleLogin,
    handleSignUp
  };
};

__sfc_main.components = Object.assign({
  DButton,
  ModalEmailVerification,
  ModalCannotLogin
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.header__icon-dietela {
  width: 110px;
}
.header__icon-login {
  width: 60%;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #008C81;
  margin: 16px 0;
}

.tab-header {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  max-height: 41px;
}

.tab-header__item {
  padding: 6px 0;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #008C81;
  cursor: pointer;
  background-color: #C7F9E3;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition-property: all;
  transition-duration: 200ms;
  animation-duration: 200ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
}

/* .tab-header__item:first-child {
  border-left: 1px solid #008C81;
}

.tab-header__item:last-child {
  border-right: 1px solid #008C81;
} */

.tab-header__item:hover {
  filter: brightness(0.95);
}

.tab-header__item--active {
  padding: 10px 0;
  font-weight: 700;
  color: white;
  background-color: #008C81;
}
.tab-form {
  padding: 16px 12px;
  border: 2px solid #008C81;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: unset;
  overflow: auto;
}
.tab-form-action-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.tab-form-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.tab-form-action__link {
  font-size: 12px;
  font-weight: 500;
  color: #555555;
}
.tab-form-action__link--hover:hover {
  text-decoration: underline;
}
.tab-form-action__link > a {
  font-size: 12px;
  font-weight: 500;
  color: #008C81;
  cursor: pointer;
}

.formulate-input::v-deep .formulate-input-errors  {
  color: red;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 6px;
}

@media (min-width: 640px) {
  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .tab-form {
    height: 100%;
    max-height: 70vh;
    padding: 25px;
  }

  /* .tab-form::-webkit-scrollbar {
    display: none;
  } */
  
  .tab-header {
    max-height: 60px;
  }

  .tab-header__item {
    padding: 10px 0;
    font-size: 16px;
  }
  .tab-header__item--active {
    padding: 15px 0;
    font-size: 16px;
  }

  .header__icon-login {
    width: 100%;
    max-width: 500px;
  }

  .header__icon-dietela {
    width: 130px;
  }

  .title {
    margin-top: 0;
  }
  .tab-form-action__link {
    font-size: 14px;
  }

  .tab-form-action__link > a {
    font-size: 14px;
  }
}
</style>
